// external dependencies
import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// gatsby dependencies
import { graphql } from 'gatsby';

// internal dependencies
import SEO from '../components/SEO';
import Cta from '../components/shared/Cta';
import Button from '../components/shared/Button';

const StyledArticleWrapper = styled.article`
  padding: 25px 50px 75px 50px;
  max-width: 1000px;
  margin: auto;
  p,
  li,
  a {
    font-size: 18px;
  }

  a {
    color: var(--scp-gold);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--scp-gold);
  }

  h2 {
    margin-top: 50px;
    font-size: 2.25rem;
  }
`;

const StyledHero = styled.div`
  height: 300px;
  width: 100%;
  background-color: var(--scp-light-gray);
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'proxima-nova-bold';
    color: var(--scp-blue);
  }
`;

const StyledDownloadSection = styled.div`
  max-width: 1000px;
  margin: 75px auto;
  background: var(--scp-blue);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  padding: 75px 50px;
  box-shadow: ${(props) => props.theme.effects.boxShadow};
  border-radius: ${(props) => props.theme.elements.borderRadius};

  h4 {
    color: white;
  }
`;

// component definition
const NewsletterArticleTemplate = ({ data }) => {
  const {
    pageTitle,
    metaDescription,
  } = data.contentfulNewsletterArticleTemplate;

  return (
    <section>
      <SEO title={pageTitle} description={metaDescription} />
      <StyledHero>
        <h1>{pageTitle}</h1>
      </StyledHero>
      <StyledArticleWrapper>
        {documentToReactComponents(
          JSON.parse(data.contentfulNewsletterArticleTemplate.article.raw)
        )}
      </StyledArticleWrapper>
      <StyledDownloadSection>
        <h4>Download our {pageTitle}</h4>
        <Button
          icon
          href={data.contentfulNewsletterArticleTemplate.downloadable.file.url}
        >
          Download PDF
        </Button>
      </StyledDownloadSection>
      <Cta
        ctaSupertext={
          data.contentfulNewsletterArticleTemplate.ctaSection.supertext
        }
        ctaHeadline={
          data.contentfulNewsletterArticleTemplate.ctaSection.headline
        }
        phoneNumber={
          data.contentfulNewsletterArticleTemplate.ctaSection.phoneNumber
        }
        email={data.contentfulNewsletterArticleTemplate.ctaSection.email}
        ctaImage={data.contentfulNewsletterArticleTemplate.ctaSection.image}
      />
    </section>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentfulNewsletterArticleTemplate(slug: { eq: $slug }) {
      pageTitle
      metaDescription
      slug
      article {
        raw
      }
      downloadable {
        file {
          url
        }
      }
      ctaSection {
        email
        headline
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          title
        }
        supertext
        phoneNumber
      }
    }
  }
`;

export default NewsletterArticleTemplate;
